<template>
  <div class="staking">
    <div>
      <div class="staking-head-1"></div>
      <div class="staking-head-2">
        <span>Balance</span><br />
        {{ balance.toLocaleString() }} SCG
      </div>
      <div style="clear: both"></div>
    </div>
    <section>
      <h2><span>SCG Token Setup</span></h2>
      <div class="row mb-1">
        <div class="col col-4 pt-2">Address</div>
        <div class="col text-end"><input readonly v-model="token_address" class="input"></div>
      </div>
      <!---      <div class="row mb-1">
        <div class="col col-4 pt-2">Image URL</div>
        <div class="col text-end"><input readonly v-model="token_image_url" class="input"></div>
      </div>
      <div class="row mb-1">
        <div class="col col-3">Symbol</div>
        <div class="col text-end">SCG</div>
      </div>
      <div class="row mb-1">
        <div class="col col-3">Decimal</div>
        <div class="col text-end">18</div>
      </div>
      -->
      <div class="row mb-1">
        <div class="col text-end" style="margin-top:10px">
          <button @click="addToMetamask" class="button light me-2">ADD TO METAMASK</button>
          <a href="https://www.sushi.com/swap?chainId=108&token0=NATIVE&token1=0x71CF496Fc5Bc3041496421F09b069BC1cf6c98Db">
            <button class="button light">BUY ON SUSHI</button>
          </a>
        </div>
      </div>
    </section>
    <section>
      <h2><span>Statistics</span></h2>
      <div class="row">
        <div class="col">Total Stakers</div>
        <div class="col text-end">{{ stats.userCount.toLocaleString() }}</div>
      </div>
      <div class="row">
        <div class="col col-8">Total claimed by me</div>
        <div class="col text-end">{{ stats.userClaimedSum.toLocaleString() }} TT</div>
      </div>
      <div class="row">
        <div class="col col-8">Total claimed by all</div>
        <div class="col text-end">{{ stats.totalClaimed.toLocaleString() }} TT</div>
      </div>
    </section>
    <section>
      <h2><span>Next Round</span></h2>
      <div class="row">
        <div class="col">Total staked</div>
        <div class="col text-end">{{ stats.totalPool.toLocaleString() ?? 0 }} SCG</div>
      </div>
      <div class="row">
        <div class="col">My stakings</div>
        <div class="col text-end">{{ stats.totalBalance.toLocaleString() }} SCG</div>
      </div>
      <div class="row">
        <div class="col">My share</div>
        <div class="col text-end">
          {{
            stats.totalBalance
              ? formatDecimal((stats.totalBalance / stats.totalPool) * 100)
              : 0
          }}%
        </div>
      </div>
      <div v-if="account" class="mt-1">
        <div class="row mb-1">
          <div class="col col-7"><input type="edit" v-model="deposit_amount" class="input"/></div>
          <div class="col text-end"><button :disabled="deposit_amount<=0" @click="popupOpen('stake')" class="button light">STAKE</button></div>
        </div>
        <div class="row">
          <div class="col col-7"><input type="edit" v-model="withdraw_amount" class="input"/></div>
          <div class="col text-end"><button :disabled="stats.totalBalance <= 0" @click="popupOpen('unstake')" class="button dark">UNSTAKE</button></div>
        </div>
      </div>
    </section>
    <section>
      <h2><span>Previous Round</span></h2>
      <div class="row">
        <div class="col">My reward</div>
        <div class="col text-end">
          {{ stats.claimableReward.toLocaleString() }} TT
          <button :disabled="stats.claimableReward == 0" @click="claimReward" class="button light">
            CLAIM
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col col-5">Round date</div>
        <div class="col text-end">
          {{ formatDate(stats.previousRoundDate) }}
        </div>
      </div>
    </section>
    <section>
      <h2><span>Current Round</span></h2>
      <div class="row">
        <div class="col">Round</div>
        <div class="col text-end">{{ stats.round }}</div>
      </div>
      <div class="row">
        <div class="col">Total rewards</div>
        <div class="col text-end">{{ stats.rewards.toLocaleString() }} TT</div>
      </div>
      <div class="row">
        <div class="col">Time left</div>
        <div class="col text-end">{{ formatSeconds(stats.timeLeft) }}</div>
      </div>
      <div class="row">
        <div class="col">Pool size</div>
        <div class="col text-end">{{ stats.effectivePool.toLocaleString() }} SCG</div>
      </div>
      <div class="row">
        <div class="col">My stakings</div>
        <div class="col text-end">{{ stats.effectiveBalance.toLocaleString() }} SCG</div>
      </div>
      <div class="row">
        <div class="col">My share</div>
        <div class="col text-end">
          {{
            stats.effectiveBalance
              ? formatDecimal(
                  (stats.effectiveBalance / stats.effectivePool) * 100
                )
              : 0
          }}%
        </div>
      </div>
      <div class="row">
        <div class="col">My pending reward</div>
        <div class="col text-end">{{ stats.pendingReward.toLocaleString() }} TT</div>
      </div>
      <div class="row">
        <div class="col col-5">Round date</div>
        <div class="col text-end">{{ formatDate(stats.currentRoundDate) }}</div>
      </div>
    </section>

    <PromoPopup
      :state="botPopup.state"
      @close="closePopup"
      :title="botPopup.title"
      :body="botPopup.body"
      :footer="botPopup.footer"
      :closeButton="true"
      fontSize="normal"
      @confirm-stake="popupYes"
    />
  </div>
</template>

<script>
import Web3 from "web3";

import STAKING_ABI from "../contracts/Staking.ABI.json";
import ERC20_ABI from "../contracts/ERC20.ABI.json";

import PromoPopup from "../components/PromoPopup.vue";

function toEth(num, round = 2) {
  num = num / 10 ** 18;
  var p = Math.pow(10, round);
  var n = num * p;
  return Math.floor(n) / p;
}

function toWei(num) {
  return window.web3.utils.toWei("" + num, "ether");
}

function getChainId() {
  if (!window.ethereum) {
    return Promise.resolve(null); // or reject, or a default value
  }

  // Method 1: Directly from window.ethereum
  let chainId = window.ethereum.chainId;
  if (chainId) {
    return Promise.resolve(parseInt(chainId, 16));
  }

  // Method 2: Using ethereum.request({ method: 'eth_chainId' })
  return window.ethereum
    .request({ method: "eth_chainId" })
    .then((chainId) => {
      if (chainId) {
        return parseInt(chainId, 16);
      }
      return null; // or a default value
    })
    .catch((error) => {
      console.error("Error getting chain ID:", error);
      return null; // or handle the error differently
    });
}

export default {
  components: {
    PromoPopup,
  },
  data() {
    return {
      chain_id: 0,
      web3_readonly: true,
      web3_error: "",
      admin: "0xf5c01516B430F639314b8A62A189Bc1550dBD60e",
      // staking_address: "0xc6ED6F155E46c2E36bb4E80682Fa427B247C11bD",
      staking_address: "0x1b87074c92A7d556F566fE0B90d65A80b194725F",
      token_address: "0x71CF496Fc5Bc3041496421F09b069BC1cf6c98Db",
      // token_image_url: "https://scgames.io/logo/thundercore/scg.png",
      token_image_url: "https://raw.githubusercontent.com/sushiswap/list/scg/logos/token-logos/network/thundercore/0x71CF496Fc5Bc3041496421F09b069BC1cf6c98Db.jpg",
      
      token_total_supply: 1000000,
      connected: false,
      is_staking_approved: false,
      balance: 0,
      account: "",
      accounts: [],
      stats: {
        round: 0,
        effectivePool: 0,
        totalPool: 0,
        rewards: 0,
        previousRoundDate: 0,
        currentRoundDate: 0,
        nextRoundDate: 0,
        timeLeft: 0,
        userCount: 0,
        totalClaimed: 0,
        expiredReward: 0,
        effectiveBalance: 0,
        totalBalance: 0,
        claimableReward: 0,
        pendingReward: 0,
        userClaimedSum: 0,
      },
      deposit_amount: 0,
      deposit_amount_current:0,
      withdraw_amount: 0,
      widthdraw_amount_current: 0,
      reward_amount: 1,

      popup_action: "",
      popup_message: "",
      refresher: null,

      botPopup: {
        state: false,
        title: "",
        body: "",
        footer: ""
      },
    };
  },
  methods: {
    async addToMetamask(){
      try {
        // wasAdded is a boolean. Like any RPC method, an error may be thrown.
        await window.ethereum.request({
          method: 'wallet_watchAsset',
          params: {
            type: 'ERC20', // Initially only supports ERC20, but eventually more!
            options: {
              address: this.token_address, // The address that the token is at.
              symbol: 'SCG', // A ticker symbol or shorthand, up to 5 chars.
              decimals: 18, // The number of decimals in the token
              image: this.token_image_url, // A string url of the token logo
            },
          },
        });

      } catch (error) {
        console.log(error);
      }
    },
    closePopup() {
      this.botPopup.state = false;
    },
    formatSeconds(seconds) {
      const days = Math.floor(seconds / 86400);
      const hours = Math.floor((seconds % 86400) / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      const remainingSeconds = seconds % 60;

      let result = "";
      if (days > 0) result += `${days} Days, `;
      if (hours > 0) result += `${hours} Hr, `;
      if (minutes > 0) result += `${minutes} Min, `;
      result += `${remainingSeconds} Sec`;

      return result;
    },
    formatDecimal(number) {
      return Number(number.toFixed(3));
    },
    formatDate(timestamp) {
      if (timestamp == 0) {
        return "";
      }
      let date = new Date(timestamp * 1000);
      let day = date.getDate();
      let month = date.toLocaleString("default", { month: "short" });
      let hours = date.getHours();
      let minutes = date.getMinutes();
      minutes = minutes < 10 ? "0" + minutes : minutes; // pad minutes with 0 if less than 10

      return `${day} ${month}, ${hours}:${minutes}`;
    },
    claimReward() {
      window.staking_contract.methods
        .claimReward()
        .send({ from: this.account })
        .then(
          function () {
            this.refresh(false);
          }.bind(this)
        );
    },
    popupOpen(action) {
      this.popup_action = action;
      this.botPopup.title = action;
      this.botPopup.footer = action;
      this.deposit_amount_current = this.deposit_amount;
      this.withdraw_amount_current = this.withdraw_amount;
      if (action == "stake") {
        this.botPopup.body =
          "Your stakings will take effect only from next round";
      } else if (action == "unstake") {
        if (
          this.withdraw_amount_current >
          this.stats.totalBalance - this.stats.effectiveBalance
        ) {
          let withdraw_current = this.formatDecimal(
            this.withdraw_amount_current -
              (this.stats.totalBalance - this.stats.effectiveBalance)
          );
          let withdraw_percent = this.formatDecimal(
            (withdraw_current / this.stats.effectiveBalance) * 100
          );
          this.botPopup.body =
            "You will loose " +
            withdraw_percent +
            "% rewards for the unstaked " +
            withdraw_current +
            " SCG from the current round";
        } else {
          this.botPopup.body =
            "You are about to unstake " +
            this.withdraw_amount_current +
            " SCG from next round";
        }
      }
      this.botPopup.state = true;
    },
    popupYes() {
      this.botPopup.state = false;
      if (this.popup_action == "stake") {
        this.depositTokens();
      } else if (this.popup_action == "unstake") {
        this.withdrawTokens();
      }
    },
    withdrawExpiredReward() {
      window.staking_contract.methods
        .withdrawExpiredReward()
        .send({ from: this.account })
        .then(
          function () {
            this.refresh(false);
          }.bind(this)
        );
    },
    doDeposit() {
      window.staking_contract.methods
        .depositTokens(toWei(this.deposit_amount_current))
        .send({ from: this.account })
        .then(
          function () {
            this.refresh(false);
          }.bind(this)
        );
    },
    depositTokens() {
      if (this.account) {
        window.token_contract.methods
          .allowance(this.account, this.staking_address)
          .call({ from: this.account })
          .then(
            function (data) {
              let allowance = toEth(data, 3);
              if (allowance < this.balance) {
                window.token_contract.methods
                  .approve(this.staking_address, toWei(this.token_total_supply))
                  .send({ from: this.account })
                  .then(
                    function () {
                      this.doDeposit();
                    }.bind(this)
                  );
              } else {
                this.doDeposit();
              }
            }.bind(this)
          );
      }
    },
    withdrawTokens() {
      window.staking_contract.methods
        .withdrawTokens(toWei(this.withdraw_amount_current))
        .send({ from: this.account })
        .then(
          function () {
            this.refresh(false);
          }.bind(this)
        );
    },
    addReward() {
      window.staking_contract.methods
        .addReward()
        .send({ from: this.account, value: toWei(this.reward_amount) })
        .then(
          function () {
            this.refresh(false);
          }.bind(this)
        );
    },
    updateRound() {
      window.staking_contract.methods
        .updateRound()
        .send({ from: this.account })
        .then(
          function () {
            this.refresh(false);
          }.bind(this)
        );
    },
    init() {
      this.connected = true;

      window.staking_contract = new window.web3.eth.Contract(STAKING_ABI);
      window.staking_contract.options.address = this.staking_address;
      window.token_contract = new window.web3.eth.Contract(ERC20_ABI);
      window.token_contract.options.address = this.token_address;

      if (this.web3_readonly) {
        this.refresh();
      } else {
        window.web3.eth.getAccounts(
          function (error, accounts) {
            if (error != null) {
              console.error("Error:", error);
            } else if (accounts.length == 0) {
              console.error("Error: Accounts empty");
            } else if (this.chain_id == 108) {
              this.account = accounts[0];
            }
            this.refresh();
          }.bind(this)
        );
      }
    },
    refresh() {
      if (!this.connected) {
        return;
      }
      window.staking_contract.methods
        .getStats()
        .call({ from: this.account })
        .then(
          function (data) {
            this.stats.round = data.round;
            this.stats.effectivePool = toEth(data.effectivePool, 3);
            this.stats.totalPool = toEth(data.totalPool, 3);
            this.stats.rewards = toEth(data.rewards, 3);
            this.stats.effectiveBalance = toEth(data.effectiveBalance, 3);
            this.stats.totalBalance = toEth(data.totalBalance, 3);
            this.stats.claimableReward = toEth(data.claimableReward, 3);
            this.stats.pendingReward = toEth(data.pendingReward, 3);
            this.stats.currentRoundDate = data.currentRoundDate;
            this.stats.previousRoundDate = data.previousRoundDate;
            this.stats.nextRoundDate = data.nextRoundDate;
            this.stats.timeLeft = data.timeLeft;
            this.stats.userCount = data.userCount;
            this.stats.userClaimedSum = toEth(data.userClaimedSum, 3);
            this.stats.totalClaimed = toEth(data.totalClaimed, 3);
            this.stats.expiredReward = toEth(data.expiredReward, 3);

            if (this.account) {
              window.token_contract.methods
                .balanceOf(this.account)
                .call({ from: this.account })
                .then(
                  function (data) {
                    // console.log("Got balance",data)
                    this.balance = toEth(data, 3);
                    this.deposit_amount = this.balance;
                    this.withdraw_amount = this.stats.totalBalance;
                  }.bind(this)
                );
            }

            // console.log(data)
          }.bind(this)
        );
    },
    connect() {
      if (window.ethereum) {
        getChainId()
          .then(
            function (chain_id) {
              this.chain_id = chain_id;
              if (chain_id === 108) {
                window.ethereum.enable().then(
                  function () {
                    window.web3 = new Web3(window.ethereum);
                    this.web3_readonly = false;
                    this.init();
                  }.bind(this),
                  function () {
                    console.log("Error: User rejected MetaMask");
                  }
                );
              } else {
                window.web3 = new Web3("https://mainnet-rpc.thundercore.com");
                this.init();
              }
            }.bind(this)
          )
          .catch(
            function (error) {
              this.web3_error = error;
            }.bind(this)
          );
      } else {
        window.web3 = new Web3("https://mainnet-rpc.thundercore.com");
        this.init();
      }
    },
    reconnect(new_chain_id) {
      if (new_chain_id != this.chain_id) {
        this.web3_readonly = true;
        this.account = "";
        this.connected = false;
        if (this.chain_id == 108) {
          console.log("Non ThunderCore");
          this.chain_id = new_chain_id;
          window.web3 = new Web3("https://mainnet-rpc.thundercore.com");
          this.init();
        } else {
          console.log("Is ThunderCore");

          this.chain_id = new_chain_id;
          window.ethereum.enable().then(
            function () {
              console.log("Setting new web3");
              window.web3 = new Web3(window.ethereum);
              this.web3_readonly = false;
              this.init();
            }.bind(this),
            function () {
              console.log("Error: User rejected MetaMask");
            }
          );
        }
      }
    },
  },
  mounted() {
    this.connect();
    if (window.ethereum) {
      window.ethereum.on(
        "chainChanged",
        function (data) {
          const new_chain_id = parseInt(data, 16);
          console.log("Network changed", new_chain_id);
          this.reconnect(new_chain_id);
        }.bind(this)
      );
    }
    this.refresher = setInterval(
      function () {
        this.refresh();
      }.bind(this),
      10000
    );
  },

  beforeDestroy() {
    if (window.ethereum) {
      window.ethereum.removeListener("chainChanged");
    }
    clearInterval(this.refresher);
  },
};
</script>
<style scoped>
.staking-head-1 {
  background: url("/images/staking-bg.png") no-repeat left center;
  min-width: 50%;
  min-height: 80px;
  float: left;
}
.staking-head-2 {
  background: url("/images/staking-bg.png") no-repeat right 40px;
  background-size: 30%;
  min-width: 50%;
  min-height: 80px;
  float: right;
  text-align: right;
  padding-right: 10%;
  padding-top: 20px;
}
.staking-head-2 span {
  font-size: 0.8rem;
  font-weight: 400;
}
.staking-head-2 br {
  content: "";
  margin: 2em;
  display: block;
  font-size: 1%;
}
.staking section {
  text-align: left;
  background: #fff;
  width: 96%;
  margin-left: 2%;
  border-radius: 10px;
  padding: 20px 20px;
  margin-bottom: 20px;
}
.staking section h2 {
  font-size: 1rem;
  position: relative;
}

.staking section h2 span {
  position: relative;
  background-color: white;
  padding-right: 10px;
  z-index: 100 !important;
  display: inline-block;
}

.staking section h2:after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background: #c5c5c5;
  right: 0;
  top: 50%;
  position: absolute;
}
.truncate {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.input {
  background: #EAEDF0;
  border-radius: 5px;
  height: 40px;
  width:100%;
  border-color: transparent;
  padding: 0px 10px;
  outline-color: #ffe81c;
}

.button {
  padding:8px 20px;
  border-radius: 5px;
  border-color: transparent;
  font-size: 12px;
}
.button.light {
  background: #FBE417;
}
.button.dark {
  background: #343D45;
  color: #FFFFFF;
}
.button.dark[disabled] {
  opacity: 0.4;
}
</style>